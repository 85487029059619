<script lang="ts" setup>
import { useTranslation } from 'i18next-vue';
import { TranslationNamespaceEnum } from "../../../locale/settings/translation-namespaces.js";

const props = defineProps<{
  customData?: Record<string, any>;
}>();
const data = props.customData as Record<string, any>;
const { t } = useTranslation();
</script>

<template>
  <div class="doc-info col-xl-10">
    <h5>{{ t("rc-about-doc", {
      ns: TranslationNamespaceEnum.customTranslations
    }) }}</h5>
    <p v-html="data.summary"></p>
    <div class="document-download-info">
      <i class="far fa-file-pdf"></i>
      <div v-if="data.fileFormat !== ''" class="file-type-name">
        {{ data.fileFormat }}
      </div>
      <div v-if="data.fileSize !== ''" class="file-size">
        <span class="mx-3">—</span>
        {{ data.fileSize }}
      </div>
    </div>
  </div>
</template>